<script setup lang="ts">
import { string, object } from 'yup';
import { useForm } from 'vee-validate';
import { ref, reactive, useAuth, useI18n, useToast, computed, watch } from '#imports';

type Feedback = {
  comment: string;
  concern: string;
  specification: string;
};

interface IProps {
  isMobile?: boolean;
}

interface Emits {
  (e: 'feedbackValue', value: Feedback): void;
}

defineProps<IProps>();
const emit = defineEmits<Emits>();
const toast = useToast('csat_concern');
const auth = useAuth();
const { t } = useI18n();

const feedbackValue = reactive<Feedback>({
  comment: '',
  concern: '',
  specification: '',
});

const specificationList = computed(() => [
  {
    id: 1,
    value: 'POOR_WEBSITE_APPS_PERFORMANCE',
    label: t('csat.categorypoorweb'),
    specifications: [
      {
        value: 'LOADING_TIME_IS_TOO_SLOW',
        label: t('csat.subcategorypoorweb1'),
      },
      {
        value: 'LOTS_OF_BUGS',
        label: t('csat.subcategorypoorweb2'),
      },
      {
        value: 'GLITCH',
        label: t('csat.subcategorypoorweb3'),
      },
      {
        value: 'OTHERS',
        label: t('csat.subcategoryothers'),
      },
    ],
    commentRequired: false,
  },
  {
    id: 2,
    value: 'POOR_INVENTORY',
    label: t('csat.categorypoorinventory'),
    specifications: [
      {
        value: 'COULD_NOT_FIND_AIRLINES',
        label: t('csat.subcategorypoorinventory1'),
      },
      {
        value: 'COULD_NOT_FIND_PROPERTIES',
        label: t('csat.subcategorypoorinventory2'),
      },
      {
        value: 'OTHERS',
        label: t('csat.subcategoryothers'),
      },
    ],
    commentRequired: false,
  },
  {
    id: 3,
    value: 'EXPENSIVE_PRICE',
    label: t('csat.categoryexpensiveprice'),
    specifications: [
      {
        value: 'FLIGHT_TICKETS',
        label: t('csat.subcategoryexpensive1'),
      },
      {
        value: 'ROOM_RATES',
        label: t('csat.subcategoryexpensive2'),
      },
      {
        value: 'PROCESSING_FEE_IS_TOO_EXPENSIVE',
        label: t('csat.subcategoryexpensive3'),
      },
      {
        value: 'OTHERS',
        label: t('csat.subcategoryothers'),
      },
    ],
    commentRequired: false,
  },
  {
    id: 4,
    value: 'LACK_OF_INFORMATION',
    label: t('csat.categorylackinfo'),
    specifications: [
      {
        value: 'FLIGHT_FACILITIES',
        label: t('csat.subcategorylackinfo1'),
      },
      {
        value: 'FLIGHT_CHECKIN_PROCEDURE',
        label: t('csat.subcategorylackinfo2'),
      },
      {
        value: 'HOTEL_ROOM_FACILITIES',
        label: t('csat.subcategorylackinfo3'),
      },
      {
        value: 'HOTEL_CHECKIN_PROCEDURE',
        label: t('csat.subcategorylackinfo4'),
      },
      {
        value: 'OTHERS',
        label: t('csat.subcategoryothers'),
      },
    ],
    commentRequired: false,
  },
  {
    id: 5,
    value: 'POOR_CUSTOMER_SERVICE',
    label: t('csat.categorypoorservice'),
    specifications: [
      {
        value: 'NO_ANSWER_CALL',
        label: t('csat.subcategorypoorservice1'),
      },
      {
        value: 'CALL_DISCONECTED',
        label: t('csat.subcategorypoorservice2'),
      },
      {
        value: 'AIRPAZ_PHONE_NUMBER_NOT_WORKING',
        label: t('csat.subcategorypoorservice3'),
      },
      {
        value: 'CUSTOMER_SUPPORT_BAD_INFORMATIONS',
        label: t('csat.subcategorypoorservice4'),
      },
      {
        value: 'EMAIL_IS_NOT_ANSWERED',
        label: t('csat.subcategorypoorservice5'),
      },
      {
        value: 'OTHERS',
        label: t('csat.subcategoryothers'),
      },
    ],
    commentRequired: false,
  },
  {
    id: 6,
    value: 'LACK_PAYMENT_OPTIONS',
    label: t('csat.categorypaymentoption'),
    specifications: [],
    commentRequired: true,
  },
  {
    id: 7,
    value: 'CONCERN_RELIABILITY',
    label: t('csat.categoryreliability'),
    specifications: [],
    commentRequired: false,
  },
]);

const disabledSubmitFormFeedback = computed(() => {
  const checkSpec = getSpecificationByConcern(values.concern)?.length;
  const validate = Boolean(values.concern) && (!checkSpec || Boolean(values.specification));
  return !validate;
});

const validateTimer = ref<boolean>(false);

const feedbackSchema = ref<any>(
  object({
    comment: string(),
    concern: string().required(),
    specification: string(),
  }),
);

const { handleSubmit, values, resetForm } = useForm<Feedback>({
  validationSchema: feedbackSchema,
});

const isCommentRequired = computed(() => {
  const concernValues = specificationList.value.find((concernVal) => concernVal.value === values.concern);
  return concernValues && (concernValues.commentRequired || values.specification === 'OTHERS');
});

const onSubmit = handleSubmit((values: Feedback) => {
  if (isCommentRequired.value && !values.comment) {
    if (!validateTimer.value) {
      toast.show({
        type: 'error',
        message: t('csat.checkyourform'),
        group: 'csat_concern',
      });
      validateTimer.value = true;
      setTimeout(() => (validateTimer.value = false), 3000);
    }
    return;
  }

  feedbackValue.concern = values.concern;
  feedbackValue.specification = values.specification;
  feedbackValue.comment = values.comment;
  emit('feedbackValue', feedbackValue);
  resetForm();
});

function getSpecificationByConcern(concern: string) {
  return specificationList.value.find((concernVal) => concernVal.value === concern)?.specifications;
}

watch(
  () => values.concern,
  (val, prev) => {
    if (val !== prev) values.specification = '';
  },
);
</script>
<template>
  <div
    id="csat_concern"
    class="container"
  >
    <form
      :class="{ 'mt-20': !isMobile }"
      @submit="onSubmit"
    >
      <h1 class="font-bold text-medium">{{ $t('csat.csattitle') }}</h1>

      <ApzSelect
        name="concern"
        :placeholder="$t('csat.csatplaceholder')"
        :variant="!isMobile ? 'outline' : 'default'"
        :wrapper-class="isMobile ? 'w-[100%] my-20' : 'w-[60%] my-20'"
      >
        <option
          v-for="concern in specificationList"
          :key="concern.id"
          :value="concern.value"
        >
          {{ concern.label }}
        </option>
      </ApzSelect>

      <div v-if="values.concern && getSpecificationByConcern(values.concern)?.length">
        <p class="font-bold text-medium">{{ $t('csat.csatsubtitle1') }}</p>
        <ApzRadio
          v-for="(specification, index) in getSpecificationByConcern(values.concern)"
          :key="index"
          :native-value="specification.value"
          name="specification"
          wrapper-class="mt-15"
          is-mobile
        >
          {{ specification.label }}
        </ApzRadio>
      </div>
      <div
        v-if="values.concern"
        class="mb-15"
        :class="isMobile ? 'mt-15' : 'mt-30'"
      >
        <div class="font-bold text-medium">
          {{ $t('csat.sharefeedbacktitle') }}
          <span
            v-if="isCommentRequired"
            class="text-danger"
          >
            *
          </span>
        </div>

        <ApzInput
          name="comment"
          type="textarea"
          :wrapper-class="isMobile ? 'mt-15 w-[100%]' : 'mt-15 w-[60%]'"
          :placeholder="$t('csat.sharefeedbackplaceholder')"
          :row="5"
        />
      </div>
      <div
        v-if="isMobile"
        class="fixed bottom-0 bg-white inset-x-0 shadow-sm px-15"
      >
        <ApzButton
          type="primary"
          class="my-15 w-full"
          :class="{ 'bg-gradient-to-r from-primary to-primary-dark': !disabledSubmitFormFeedback }"
          native-type="submit"
          :disabled="disabledSubmitFormFeedback"
        >
          {{ auth.loggedIn ? $t('global.submit') : $t('result.continue') }}
        </ApzButton>
      </div>

      <ApzButton
        v-else
        type="primary"
        native-type="submit"
        class="float-right mb-20 px-[34px] py-[16px] min-w-[127px]"
        :disabled="disabledSubmitFormFeedback"
      >
        {{ auth.loggedIn ? $t('global.submit') : $t('result.continue') }}
      </ApzButton>
    </form>
  </div>
  <ClientOnly>
    <ApzToast
      container="#csat_concern"
      group="csat_concern"
      :full-width="isMobile"
    />
  </ClientOnly>
</template>
